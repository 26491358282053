/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createHours = async (data) => {
    try {
      const res = await axios.post(`/api/v1/hours`,
        data
      );
      if (res.data.status === 'success') {
        showAlert('success', 'Poprawnie dodano godziny');
        window.setTimeout(() => {
          location.reload(true);
        }, 2000);
      }
    } catch (err) {
      showAlert('danger', err.response.data.message);
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  };

  export const deleteHours = async (id) => {
    try {
      
      const res = await axios.delete(`/api/v1/hours/${id}`);
      if (res.status === 204) {
        showAlert('success', 'Poprawnie usunięto godziny');
        window.setTimeout(() => {
          location.reload(true);
        }, 2000);
      }
    }catch(err){
      showAlert('danger', err.response.data.message);
      
    }
  }