/* eslint-disable */
const createOverlay = () => {
    const overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);
    return overlay;
};

const removeOverlay = (overlay) => {
    if (overlay && overlay.parentNode) {
        overlay.parentNode.removeChild(overlay);
    }
};
export const hideAlert = () => {
    const alertEl = document.querySelector('.alert');
    const overlayEl = document.querySelector('.overlay');

    if (alertEl) alertEl.parentElement.removeChild(alertEl);
    if (overlayEl) overlayEl.parentElement.removeChild(overlayEl);
};
export const showAlert = (type, msg) => {
    hideAlert();
    const markup = `<div class="alert alert-${type}" role="alert">${msg}</div>`;
    document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
    window.setTimeout(hideAlert, 3000);
}
export const showAlertOverlay = (type, msg) => {
    hideAlert();
    createOverlay();
    const markup = `<div class="alert alert-${type}" role="alert">${msg}</div>`;
    document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
    window.setTimeout(hideAlert, 3000);
}

export const showAlertInfo = (type, msg) => {
    // Utwórz overlay dynamicznie
    createOverlay();

    const markup = `<div class="alert alert-${type}" role="alert">${msg}</div>`;
    document.querySelector('body').insertAdjacentHTML('afterbegin', markup);

 
};