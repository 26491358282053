/* eslint-disable */
import axios from 'axios';
import {
  showAlert,
  hideAlert,
  showAlertInfo,
  showAlertOverlay
} from './alerts';

export const uploadIndPhoto = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/indtrainings/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Zaktualizowano dane');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};

export const deleteTrainingIndividual = async id => {
  try {
    const res = await axios.delete(`/api/v1/indtrainings/${id}`);
    if (res.status === 204) {
      showAlert('success', 'Poprawnie usunięto trening');
      window.setTimeout(() => {
        location.assign(`/individual/add`);
      }, 1500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const createIndividualTraining = async data => {
  try {
    const res = await axios.post(`/api/v1/indtrainings`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie dodano trening');
      window.setTimeout(() => {
        location.reload(true);
      }, 2000);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 2500);
  }
};

export const updateStatus = async id => {
  try {
    const res = await axios.patch(`/api/v1/indtrainings/change-status/${id}`);
    if (res.status === 204) {
      showAlert('success', 'Zmieniono status');
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const updateIndividualTraining = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/indtrainings/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res.status === 204) {
      showAlert('success', 'Zaktualizowane dane');
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const trainingSignup = async (date, time, id) => {
  try {
    showAlertInfo('info', 'Proszę czekać, trwa zapisywanie na trening...');

    const res = await axios.patch(
      `/api/v1/indtrainings/signup-training/${id}`,
      { date, time },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (res.status === 200) {
      showAlertOverlay('success', 'Poprawnie zapisano na trening');
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  } catch (err) {
    hideAlert();
    showAlert('danger', err.response.data.message);
  }
};

export const confirmSignup = async (date, playerId, id) => {
  try {
    showAlertInfo(
      'info',
      'Trwa potwierdzenie i wysyłka e-mail do uczestnika...'
    );
    const res = await axios.patch(
      `/api/v1/indtrainings/confirm-training/${id}`,
      { date, playerId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (res.status === 200) {
      showAlertOverlay('success', 'Potwierdzono trening');
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const cancelTraining = async (date, playerId, id, userDelete) => {
  try {
    const res = await axios.patch(
      `/api/v1/indtrainings/delete-user-training/${id}`,
      { date, playerId, userDelete },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (res.status === 200) {
      showAlert('success', 'Odwołano trening');
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const indAttendanceUpdate = async (id, playerId, startDate, playerStatus) => {
  try {
    const res = await axios.patch(
      `/api/v1/indtrainings/attendence/${id}`,
      { playerId, startDate, playerStatus },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie zmodyfikowano obecność');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    // window.setTimeout(() => {
    //   location.reload(true);
    // }, 1500);
  }
};
