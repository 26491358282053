/* eslint-disable */
//import '@babel/polyfill';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl.js';
import { showAlert } from './alerts';
import {
  login,
  logout,
  resetPassword,
  changePassword,
  signup,
  uploadUserPhoto,
  updateUserStatus
} from './login';
import {
  createIndividualTraining,
  updateStatus,
  updateIndividualTraining,
  deleteTrainingIndividual,
  uploadIndPhoto,
  trainingSignup,
  confirmSignup,
  cancelTraining,
  indAttendanceUpdate
} from './indtraining';
import {
  createTraining,
  addUserToTraining,
  deleteUserFromTraining,
  updateTraining,
  deleteTraining,
  attendaceUpdate
} from './training';
import { createHours, deleteHours } from './hours';
import { Calendar } from 'fullcalendar';
import plLocale from '@fullcalendar/core/locales/pl';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';

function setCalendarHeight(calendar) {
  const events = calendar.getEvents();

  const numberOfEvents = events.length;
  const minCalculatedHeight = 400; // Set a minimum height in pixels
  const screenWidthThreshold = 765; // Set the threshold width

  let calculatedHeight = 600 + 'px'; // Adjust the multiplier based on your layout
  // Ensure a minimum height is set only when the screen width is smaller than the threshold
  if (window.innerWidth > screenWidthThreshold) {
    calculatedHeight = 100 + '%';
  }

  const calendarEl = calendar.el;

  // Set the height dynamically
  calendarEl.style.height = calculatedHeight;
}
const navigationLinks = document.querySelectorAll('.offcanvas-body a');
if (navigationLinks) {
  navigationLinks.forEach(link => {
    if (link.getAttribute('href') === window.location.pathname) {
      link.classList.add('active');
    }
  });
}
const loginForm = document.querySelector('#form-login');
if (loginForm) {
  loginForm.addEventListener('submit', e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });
}
const logOutBtn = document.getElementById('logout');
if (logOutBtn) {
  logOutBtn.addEventListener('click', logout);
}
const signupForm = document.getElementById('signup-form');
if (signupForm) {
  signupForm.addEventListener('submit', e => {
    e.preventDefault();
    const registerUsername = document.getElementById('name').value;
    const registerUsersurname = document.getElementById('surName').value;
    const registerEmail = document.getElementById('email').value;
    const registerPassword = document.getElementById('password').value;
    const confirmPassword = document.getElementById('passwordConfirm').value;
    const phoneNumber = document.getElementById('phoneNumber').value;
    if (registerPassword !== confirmPassword) {
      showAlert('danger', 'Hasła nie są takie same!');
    } else {
      signup(
        registerUsername,
        registerUsersurname,
        registerEmail,
        phoneNumber,
        registerPassword,
        confirmPassword
      );
    }
  });
}
const forgotPassword = document.getElementById('form-reset');
if (forgotPassword) {
  forgotPassword.addEventListener('submit', event => {
    event.preventDefault();
    const email = document.getElementById('email').value;
    resetPassword(email);
  });
}

const resetPasswordForm = document.getElementById('reset-password-form');
if (resetPasswordForm) {
  const resetToken = window.location.pathname.split('/').pop();
  resetPasswordForm.addEventListener('submit', function(event) {
    event.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('passwordConfirm').value;
    changePassword(password, passwordConfirm, resetToken);
  });
}

const addTraining = document.getElementById('add-training');
if (addTraining) {
  const datePickerEl = document.getElementById('date-picker');
  const starttimePickerEl = document.getElementById('start-time-picker');
  const endtimePickerEl = document.getElementById('end-time-picker');
  const trainingNameEl = document.getElementById('training-name');
  const trainingDescriptionEl = document.getElementById('training-description');
  const groupSizeEl = document.getElementById('group-size');
  const priceEl = document.getElementById('price');
  const addressEl = document.getElementById('address');

  const datePicker = flatpickr(datePickerEl, {
    allowInput: true,
    dateFormat: 'm/d/Y',
    mode: 'multiple',
    locale: Polish
  });
  const startTimePicker = flatpickr(starttimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });
  const endTimePicker = flatpickr(endtimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });

  addTraining.addEventListener('submit', e => {
    e.preventDefault();
    const selectedDates = datePicker.selectedDates;
    const startSelectedTime = startTimePicker.selectedDates[0];
    const endSelectedTime = endTimePicker.selectedDates[0];
    const trainingName = trainingNameEl.value.trim();
    const trainingDescription = trainingDescriptionEl.value.trim();
    const groupSize = groupSizeEl.value;
    const price = priceEl.value;
    const address = addressEl.value;

    // Sprawdzenie, czy start jest większy od end
    if (startSelectedTime > endSelectedTime) {
      showAlert(
        'danger',
        'Czas rozpoczęcia treningu nie może być późniejszy niż czas zakończenia!'
      );
      return;
    }

    const trainingData = selectedDates.map(date => {
      const formattedDate = flatpickr.formatDate(date, 'Y-m-d');
      const startFormattedTime = flatpickr.formatDate(startSelectedTime, 'H:i');
      const endFormattedTime = flatpickr.formatDate(endSelectedTime, 'H:i');

      return {
        name: trainingName,
        startDate: formattedDate + 'T' + startFormattedTime + ':00.000Z',
        endDate: formattedDate + 'T' + endFormattedTime + ':00.000Z',
        description: trainingDescription,
        groupSize,
        price,
        address
      };
    });

    createTraining(trainingData);
  });
}

const calendarEl = document.getElementById('calendar');
if (calendarEl) {
  // Wyświetl kalendarz
  const calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    slotMinTime: '06:00',
    slotMaxTime: '23:00',
    timeZone: 'UTC',
    initialView: window.innerWidth >= 765 ? 'dayGridMonth' : 'listWeek',
    events: function(info, successCallback, failureCallback) {
      const startDate = new Date(info.start);
      const startDateFormatted = startDate.toISOString().split('T')[0];
      const endDate = new Date(info.end);
      const endDateFormatted = endDate.toISOString().split('T')[0];

      fetch(
        `/api/v1/trainings?start=${startDateFormatted}&end=${endDateFormatted}`
      )
        .then(response => response.json())
        .then(data => {
          const trainings = data.data.trainings;
          const events = trainings.map(training => ({
            id: training._id,
            title: training.name,
            start: training.startDate,
            end: training.endDate,
            url: `/trainings/${training._id}` // Adres URL do treningu
          }));
          successCallback(events);
          setCalendarHeight(calendar);
        })
        .catch(err => {
          console.error(err);
          failureCallback(new Error('Error fetching events'));
        });
    },
    eventClick: function(info) {
      // Przekierowanie do strony treningu po kliknięciu na wydarzenie
      window.location.href = info.event.url;
    },
    datesSet: event => {
      //console.log(event.start.getFullYear());
    },
    locale: plLocale
  });

  calendar.render();
}

const calendarEl2 = document.getElementById('calendar-2');
if (calendarEl2) {
  // Wyświetl kalendarz
  const calendar = new Calendar(calendarEl2, {
    plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,listWeek'
    },
    slotMinTime: '06:00',
    slotMaxTime: '23:00',
    //themeSystem: 'bootstrap5',
    //plugins: [bootstrap5Plugin],
    timeZone: 'UTC',
    initialView: window.innerWidth >= 765 ? 'dayGridMonth' : 'listWeek',
    events: function(info, successCallback, failureCallback) {
      const startDate = new Date(info.start);
      const startDateFormatted = startDate.toISOString().split('T')[0];
      const endDate = new Date(info.end);
      const endDateFormatted = endDate.toISOString().split('T')[0];

      fetch(
        `/api/v1/trainings?start=${startDateFormatted}&end=${endDateFormatted}`
      )
        .then(response => response.json())
        .then(data => {
          const trainings = data.data.trainings;
          const events = trainings.map(training => ({
            id: training._id,
            title: training.name,
            start: training.startDate,
            end: training.endDate,
            url: `/trainings/${training._id}` // Adres URL do treningu
          }));
          successCallback(events);
          setCalendarHeight(calendar);
        })
        .catch(err => {
          console.error(err);
          failureCallback(new Error('Error fetching events'));
        });
    },

    eventClick: function(info) {
      // Przekierowanie do strony treningu po kliknięciu na wydarzenie
      window.location.href = info.event.url;
    },
    datesSet: event => {
      //console.log(event.start.getFullYear());
    },
    viewDidMount: function(view) {
      // Call the function to set the calendar height after the view is mounted
      setCalendarHeight(calendar);
    },
    validRange: {
      start: new Date()
    },
    locale: plLocale
  });

  calendar.render();
}

const addToTraining = document.getElementById('add-to-training');
if (addToTraining) {
  const trainingId = window.location.pathname.split('/').pop();
  const groupSize = document.getElementById('group-size').textContent;
  const tabela = document.getElementById('group-table-overview').rows.length;
  if (tabela - 1 >= Number(groupSize)) {
    addToTraining.setAttribute('data-bs-toggle', 'modal');
    addToTraining.setAttribute('data-bs-target', '#info-reserve-modal');
  }

  addToTraining.addEventListener('click', e => {
    if (tabela - 1 >= Number(groupSize)) {
      // Pokaż modal
      const reserveModal = document.getElementById('info-reserve-modal');
      reserveModal.classList.add('show');
      reserveModal.style.display = 'block';

      // Dodaj obsługę przycisku potwierdzającego wewnątrz modalu
      const confirmBtn = document.getElementById('confirmSignupBtn');
      confirmBtn.addEventListener('click', () => {
        // Ukryj modal
        reserveModal.classList.remove('show');
        reserveModal.style.display = 'none';
        // Wywołaj funkcję addUserToTraining z odpowiednimi parametrami
        addUserToTraining(trainingId, 'rezerwa');
      });
    } else {
      addUserToTraining(trainingId);
    }
  });
}
const removeMyselfFromTraining = document.getElementById(
  'remove-from-training'
);
if (removeMyselfFromTraining) {
  const trainingId = window.location.pathname.split('/').pop();
  removeMyselfFromTraining.addEventListener('click', () => {
    deleteUserFromTraining(trainingId);
  });
}

const groupTableOverview = document.getElementById(
  'group-table-overview-delete'
);
if (groupTableOverview) {
  const removePlayerButtons = document.querySelectorAll('.remove-player-btn');
  const trainingId = window.location.pathname.split('/').pop();
  removePlayerButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      const playerId = button.getAttribute('data-player-id');

      const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
      confirmDeleteBtn.addEventListener('click', function() {
        deleteUserFromTraining(trainingId, playerId);
        // Ukrywamy modal po kliknięciu przycisku "Usuń"
        const modalElement = document.getElementById('confirmDeleteModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      });
    });
  });
}

const editTraining = document.getElementById('edit-training');
if (editTraining) {
  const datePickerEl = document.getElementById('date-picker');
  const starttimePickerEl = document.getElementById('start-time-picker');
  const endtimePickerEl = document.getElementById('end-time-picker');
  const trainingNameEl = document.getElementById('training-name');
  const trainingDescriptionEl = document.getElementById('training-description');
  const groupSizeEl = document.getElementById('group-size');
  const priceEl = document.getElementById('price');
  const addressEl = document.getElementById('address');

  const datePicker = flatpickr(datePickerEl, {
    allowInput: true,
    dateFormat: 'm/d/Y',
    mode: 'single',
    locale: Polish
  });
  const startTimePicker = flatpickr(starttimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });
  const endTimePicker = flatpickr(endtimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });

  editTraining.addEventListener('submit', e => {
    e.preventDefault();
    const selectedDates = datePicker.selectedDates[0];
    const startSelectedTime = startTimePicker.selectedDates[0];
    const endSelectedTime = endTimePicker.selectedDates[0];
    const trainingName = trainingNameEl.value.trim();
    const trainingDescription = trainingDescriptionEl.value.trim();
    const groupSize = groupSizeEl.value;
    const price = priceEl.value;
    const address = addressEl.value;

    // Sprawdzenie, czy start jest większy od end
    if (startSelectedTime > endSelectedTime) {
      showAlert(
        'danger',
        'Czas rozpoczęcia treningu nie może być późniejszy niż czas zakończenia!'
      );
      return;
    }

    const formattedDate = flatpickr.formatDate(selectedDates, 'Y-m-d');
    const startFormattedTime = flatpickr.formatDate(startSelectedTime, 'H:i');
    const endFormattedTime = flatpickr.formatDate(endSelectedTime, 'H:i');
    const trainingData = {
      name: trainingName,
      startDate: formattedDate + 'T' + startFormattedTime + ':00.000Z',
      endDate: formattedDate + 'T' + endFormattedTime + ':00.000Z',
      description: trainingDescription,
      groupSize,
      price,
      address
    };
    const trainingId = window.location.pathname.split('/').pop();
    updateTraining(trainingData, trainingId);
  });
}

const deleteTrainingButton = document.getElementById('training-delete-button');
if (deleteTrainingButton) {
  deleteTrainingButton.addEventListener('click', function() {
    const trainingId = window.location.pathname.split('/').pop();
    const confirmDeleteBtn = document.getElementById('confirmDeleteBtn2');
    confirmDeleteBtn.addEventListener('click', function() {
      deleteTraining(trainingId);

      // Ukrywamy modal po kliknięciu przycisku "Usuń"
      const modalElement = document.getElementById('confirmDeleteModal2');
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    });
  });
}

const deleteIndTrainingButton = document.getElementById(
  'trainingind-delete-button'
);
if (deleteIndTrainingButton) {
  deleteIndTrainingButton.addEventListener('click', function() {
    const trainingId = window.location.pathname.split('/').pop();
    const confirmDeleteBtn = document.getElementById('confirmDeleteBtn2');
    confirmDeleteBtn.addEventListener('click', function() {
      deleteTrainingIndividual(trainingId);

      // Ukrywamy modal po kliknięciu przycisku "Usuń"
      const modalElement = document.getElementById('confirmDeleteModal2');
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    });
  });
}

const addIndividual = document.getElementById('add-individual');
if (addIndividual) {
  addIndividual.addEventListener('submit', e => {
    e.preventDefault();
    const name = document.getElementById('training-name').value;
    const trainingType = document.getElementById('address').value;
    const description = document.getElementById('training-description').value;
    const price = document.getElementById('price').value;
    const duration = document.getElementById('duration').value;
    const address = document.getElementById('training-address').value;
    let formData = new FormData();
    formData.append('name', name);
    formData.append('trainingType', trainingType);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('duration', duration);
    formData.append('address', address);

    createIndividualTraining(formData);
  });
}

const freeDays = document.getElementById('free-days');
if (freeDays) {
  const starttimePickerEl = document.getElementById('start-time-picker');
  const endtimePickerEl = document.getElementById('end-time-picker');
  const datePickerEl = document.getElementById('date-picker');
  const allDayRadio = document.getElementById('flexRadioDefault2');
  const particularHoursRadio = document.getElementById('flexRadioDefault1');
  const hoursContainer = document.getElementById('hours-display');
  const startTimePicker = flatpickr(starttimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });
  const endTimePicker = flatpickr(endtimePickerEl, {
    allowInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  });
  const datePicker = flatpickr(datePickerEl, {
    allowInput: true,
    dateFormat: 'm/d/Y',
    mode: 'multiple',
    locale: Polish
  });
  if (particularHoursRadio.checked) {
    hoursContainer.style.display = 'flex';
  } else {
    hoursContainer.style.display = 'none';
  }
  particularHoursRadio.addEventListener('change', function() {
    hoursContainer.style.display = 'flex';
  });

  allDayRadio.addEventListener('change', function() {
    hoursContainer.style.display = 'none';
  });

  freeDays.addEventListener('submit', e => {
    e.preventDefault();

    const selectedDates = datePicker.selectedDates;
    const startSelectedTime = startTimePicker.selectedDates[0];
    const endSelectedTime = endTimePicker.selectedDates[0];
    if (startSelectedTime > endSelectedTime) {
      showAlert(
        'danger',
        'Czas rozpoczęcia treningu nie może być późniejszy niż czas zakończenia!'
      );
      return;
    }
    let startFormattedTime = '00:00';
    let endFormattedTime = '23:59';
    if (particularHoursRadio.checked) {
      startFormattedTime = flatpickr.formatDate(startSelectedTime, 'H:i');
      endFormattedTime = flatpickr.formatDate(endSelectedTime, 'H:i');
    }

    const trainingData = selectedDates.map(date => {
      const formattedDate = flatpickr.formatDate(date, 'Y-m-d');

      return {
        startDate: formattedDate + 'T' + startFormattedTime + ':00.000Z',
        endDate: formattedDate + 'T' + endFormattedTime + ':00.000Z'
      };
    });

    createHours(trainingData);
  });
}

const indTrainOverwiev = document.getElementById('ind-train-overwiev');
if (indTrainOverwiev) {
  const toggleLabel = input => {
    const label = input.nextElementSibling; // Znajdź element label
    if (input.checked) {
      label.textContent = 'aktywny';
    } else {
      label.textContent = 'nieaktywny';
    }
  };
  const activeInputs = document.querySelectorAll('.active-training');
  const inactiveInputs = document.querySelectorAll('.inactive-training');

  // Dodaj obsługę zdarzenia dla wszystkich inputów 'active-training'
  activeInputs.forEach(input => {
    input.addEventListener('change', function() {
      toggleLabel(this);
      const id = this.value; // Pobierz wartość 'value' z inputa
      updateStatus(id); // Wywołaj funkcję updateStatus z przekazanym ID
    });
  });

  // Dodaj obsługę zdarzenia dla wszystkich inputów 'inactive-training'
  inactiveInputs.forEach(input => {
    input.addEventListener('change', function() {
      toggleLabel(this);
      const id = this.value; // Pobierz wartość 'value' z inputa
      updateStatus(id); // Wywołaj funkcję updateStatus z przekazanym ID
    });
  });
}
const hoursOverwiev = document.getElementById('hours-overwiev');
if (hoursOverwiev) {
  const removePlayerButtons = document.querySelectorAll('.remove-player-btn');

  removePlayerButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      const playerId = button.getAttribute('data-player-id');

      const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
      confirmDeleteBtn.addEventListener('click', function() {
        deleteHours(playerId);
        const modalElement = document.getElementById('confirmDeleteModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      });
    });
  });
}

const editIndividualTraining = document.getElementById(
  'edit-individual-training'
);
if (editIndividualTraining) {
  const trainingId = window.location.pathname.split('/').pop();
  editIndividualTraining.addEventListener('submit', e => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const trainingType = document.getElementById('trainingType').value;
    const description = document.getElementById('description').value;
    const price = document.getElementById('price').value;
    const duration = document.getElementById('duration').value;
    const data = { name, trainingType, description, price, duration };
    updateIndividualTraining(data, trainingId);
  });
}

const uploadImageForm = document.getElementById('edit-individual-photo');

if (uploadImageForm) {
  uploadImageForm.addEventListener('submit', function(event) {
    event.preventDefault();

    const fileInput = document.getElementById('formFile');
    const file = fileInput.files[0];
    const id = document.getElementById('training-id').value;

    if (!file) {
      showAlert('danger', 'Wybierz plik do przesłania!');
      return;
    }

    // Tworzymy obiekt FormData, aby przesłać plik za pomocą żądania POST
    const formData = new FormData();
    formData.append('image', file);

    uploadIndPhoto(formData, id);
  });
}

const carouselIndividual = document.getElementById('carouselExampleControls');
if (carouselIndividual) {
  let trainingTypeValue;
  let trainingDurationValue;
  let trainingId;
  let trainingName;
  const saveTraining = document.querySelectorAll('.save-user-training-button');
  saveTraining.forEach(function(link) {
    link.addEventListener('click', e => {
      document.addEventListener('click', event => {
        const modal = document.getElementById('myModal');
        const isInsideModal = modal.contains(event.target);

        if (!isInsideModal) {
          const swiperWrapper = document.querySelector('.swiper-wrapper');
          swiperWrapper.innerHTML = '';
        }
      });
      trainingTypeValue = link.getAttribute('type');
      trainingDurationValue = link.getAttribute('duration');
      trainingId = link.getAttribute('data-id');
      trainingName = link.getAttribute('training-name');
      fetch('/api/v1/indtrainings/getDisabledDays')
        .then(response => response.json())
        .then(data => {
          const datePickerEl = document.getElementById('date-picker');
          const disabledDates = data.data.disabledDates;
          const flatpickrOptions = {
            allowInput: true,
            dateFormat: 'Y-m-d',
            mode: 'single',
            maxDate: new Date().fp_incr(30),
            minDate: 'today',
            locale: Polish,
            inline: true,
            disable: disabledDates,
            onChange: function(selectedDates, dateStr, instance) {
              fetch(
                `/api/v1/indtrainings/getTrainingsCalendar/?start=${dateStr}&type=${trainingTypeValue}&duration=${trainingDurationValue}`
              )
                .then(response => response.json())
                .then(data => {
                  const nextButton = document.querySelector(
                    '.swiper-button-next'
                  );
                  const prevButton = document.querySelector(
                    '.swiper-button-prev'
                  );
                  const swiperWrapper = document.querySelector(
                    '.swiper-wrapper'
                  );
                  // Usuń istniejące przyciski typu radio
                  swiperWrapper.innerHTML = '';

                  if (dateStr) {
                    nextButton.style.visibility = 'visible';
                    prevButton.style.visibility = 'visible';
                    const availableHours = data.data.availableHoursSend;
                    const swiper = new Swiper('.swiper', {
                      slidesPerView: 4,
                      spaceBetween: 30,
                      modules: [Navigation],
                      grabCursor: true,
                      mousewheel: true,
                      navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                      }
                    });
                    swiper.update();

                    let activeButton = null; // Przechowuje referencję do aktywnego przycisku
                    // Sprawdź, czy data została wybrana
                    // Dla każdej dostępnej godziny stwórz przycisk typu button
                    availableHours.forEach(hour => {
                      const slide = document.createElement('div');
                      slide.classList.add('swiper-slide');
                      const button = document.createElement('button');
                      button.type = 'button';
                      button.classList.add('btn');
                      button.classList.add('custom-btn');
                      button.classList.add('custom-border-btn');
                      button.innerText = hour;
                      button.value = hour;
                      slide.appendChild(button);
                      button.addEventListener('click', function() {
                        if (activeButton) {
                          activeButton.classList.remove('active-time'); // Usuwa aktywny stan z poprzedniego przycisku
                        }

                        activeButton = this; // Aktualizuje referencję do aktywnego przycisku
                        this.classList.add('active-time'); // Dodaje aktywny stan do bieżącego przycisku
                      });

                      // Dodaj przycisk do kontenera
                      swiperWrapper.appendChild(slide);
                    });
                  }
                })
                .catch(error => {
                  console.error('Błąd pobierania danych z API', error);
                });
            } // Ustaw wyłączone daty jako stringi
          };

          const datePicker = flatpickr(datePickerEl, flatpickrOptions);
        })
        .catch(error => {
          console.error('Błąd pobierania danych z API', error);
        });
    });
  });
  const saveUserTraining = document.getElementById('save-user-training');
  saveUserTraining.addEventListener('submit', e => {
    e.preventDefault();
    const trainingDate = document.getElementById('date-picker').value;
    const activeButton = document.querySelector(
      '.btn.custom-btn.custom-border-btn.active-time'
    );

    // if (activeButton) {
    //   console.log(trainingDate, activeButton.value, trainingId) ;
    // }
    const swiperEl = document.querySelector(
      '.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden'
    );
    if (activeButton) {
      //const confirmationModalLabel = document.getElementById('confirmationModalLabel');
      const confirmationModalBody = document.querySelector(
        '#confirmationModal .modal-body p'
      );
      confirmationModalBody.textContent = 'Potwierdzenie - ' + trainingName;
      confirmationModalBody.textContent =
        'Czy na pewno chcesz zapisać się na trening "' +
        trainingName +
        '" w dniu ' +
        trainingDate +
        ' o godzinie ' +
        activeButton.value +
        '?';

      const confirmationModal = new bootstrap.Modal(
        document.getElementById('confirmationModal')
      );
      confirmationModal.show();

      const confirmSaveButton = document.getElementById('confirmSaveButton');
      confirmSaveButton.addEventListener('click', e => {
        e.preventDefault();
        trainingSignup(trainingDate, activeButton.value, trainingId);
      });
    } else if (swiperEl) {
      showAlert('danger', 'Brak dostępnych godzin na wybrany dzień');
    } else {
      showAlert('danger', 'Proszę wybrać godzinę!');
    }
  });
}

const indTrainSignupOverview = document.getElementById('ind-train-overwiev2');
if (indTrainSignupOverview) {
  const expandChildTables = document.querySelectorAll('.expandChildTable');
  expandChildTables.forEach(table => {
    table.addEventListener('click', function() {
      this.classList.toggle('selected');
      const parentRow = this.closest('tr');
      const childRow = parentRow.nextElementSibling;
      childRow.style.display = childRow.style.display === 'none' ? '' : 'none';
    });
  });
  const confirmButton = document.querySelectorAll('.confirm-player-btn');
  confirmButton.forEach(button => {
    button.addEventListener('click', event => {
      const playerId = event.currentTarget.getAttribute('data-player-id');
      const trainingData = event.currentTarget.getAttribute('training-data');
      const trainingId = event.currentTarget.getAttribute('training-id');
      confirmSignup(trainingData, playerId, trainingId);
    });
  });
  const deleteButton = document.querySelectorAll(
    '.btn.btn-danger.btn-sm.remove-player-btn'
  );
  deleteButton.forEach(button => {
    button.addEventListener('click', event => {
      const playerId = event.currentTarget.getAttribute('data-player-id');
      const trainingData = event.currentTarget.getAttribute('training-data');
      const trainingId = event.currentTarget.getAttribute('training-id');
      const userDelete = event.currentTarget.getAttribute('user-delete');

      const confirmDeleteBtn = document.getElementById('confirmDeleteBtn');
      confirmDeleteBtn.addEventListener('click', function() {
        // Ukrywamy modal po kliknięciu przycisku "Usuń"
        if (userDelete) {
          cancelTraining(trainingData, playerId, trainingId, userDelete);
        } else {
          cancelTraining(trainingData, playerId, trainingId);
        }

        const modalElement = document.getElementById('confirmDeleteModal');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      });
    });
  });
}

const indCalendar = document.getElementById('ind-calendar');
if (indCalendar) {
  const calendar = new Calendar(indCalendar, {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    slotMinTime: '06:00',
    slotMaxTime: '23:00',
    timeZone: 'UTC',
    initialView: window.innerWidth >= 765 ? 'dayGridMonth' : 'listWeek',
    events: function(info, successCallback, failureCallback) {
      const startDate = new Date(info.start);
      const startDateFormatted = startDate.toISOString().split('T')[0];
      const endDate = new Date(info.end);
      const endDateFormatted = endDate.toISOString().split('T')[0];

      fetch(
        `/api/v1/indtrainings?start=${startDateFormatted}&end=${endDateFormatted}`
      )
        .then(response => response.json())
        .then(data => {
          const trainings = data.data.trainings;
          const events = trainings.map(training => ({
            title: training.name,
            extendedProps: {
              description: training.trainingType,
              playerName: training.attendeeInfo[0].name,
              playerSurName: training.attendeeInfo[0].surName,
              price: training.price
            },
            start: training.attendance.startDate,
            end: training.attendance.endDate,
            url: `/trainings/individual/${training._id}/${training.attendance.startDate}` // Adres URL do treningu
          }));
          successCallback(events);
          setCalendarHeight(calendar);
        })
        .catch(err => {
          console.error(err);
          failureCallback(new Error('Error fetching events'));
        });
    },
    datesSet: event => {
      //console.log(event.start.getFullYear());
    },
    locale: plLocale,
    eventDidMount: function(info) {
      const {
        description,
        playerName,
        playerSurName,
        price
      } = info.event.extendedProps;

      const tooltipContent = `
        <div>
          <strong>Typ:</strong> ${description}<br>
          <strong>Imię:</strong> ${playerName}<br>
          <strong>Nazwisko:</strong> ${playerSurName}<br>
          <strong>Cena:</strong> ${price}
        </div>
      `;

      $(info.el).tooltip({
        title: tooltipContent,
        html: true, // Ustawienie na true, aby móc używać HTML w treści tooltipa
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
    eventClick: function(info) {
      // Przekierowanie do strony treningu po kliknięciu na wydarzenie
      window.location.href = info.event.url;
    }
  });

  calendar.render();
}

const saveAttendance = document.getElementById('saveAttendance');
if (saveAttendance) {
  saveAttendance.addEventListener('submit', e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const id = formData.get('trainingId');
    const data = {
      trainingId: formData.get('trainingId'),
      players: []
    };
    const playerIdArray = formData.getAll('playerId[]');
    const statusArray = formData.getAll('status[]');

    for (let i = 0; i < playerIdArray.length; i++) {
      data.players.push({ playerId: playerIdArray[i], status: statusArray[i] });
    }
    attendaceUpdate(data, id);
  });
}
const saveAttendanceReserve = document.getElementById('saveAttendanceReserve');
if (saveAttendanceReserve) {
  saveAttendanceReserve.addEventListener('submit', e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const id = formData.get('trainingId');
    const data = {
      trainingId: formData.get('trainingId'),
      players: []
    };
    const playerIdArray = formData.getAll('playerId[]');
    const statusArray = formData.getAll('status[]');

    for (let i = 0; i < playerIdArray.length; i++) {
      data.players.push({ playerId: playerIdArray[i], status: statusArray[i] });
    }
    attendaceUpdate(data, id);
  });
}
const individualAttendance = document.getElementById('individualAttendance');
if (individualAttendance) {
  individualAttendance.addEventListener('submit', e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const statusSelect = document.getElementById('statusSelect');
    const id = formData.get('trainingId');
    const playerId = formData.get('playerId');
    const startDate = formData.get('startDate');
    const playerStatus = statusSelect.value;
    indAttendanceUpdate(id, playerId, startDate, playerStatus);
  });
}

const uploadUserImageForm = document.getElementById('uploadUserImageForm');
if (uploadUserImageForm) {
  uploadUserImageForm.addEventListener('submit', function(event) {
    event.preventDefault();

    const fileInput = document.getElementById('formFile');
    const file = fileInput.files[0];
    const id = document.getElementById('player-id').value;
    if (!file) {
      showAlert('danger', 'Wybierz plik do przesłania!');
      return;
    }

    // Tworzymy obiekt FormData, aby przesłać plik za pomocą żądania POST
    const formData = new FormData();
    formData.append('image', file);

    uploadUserPhoto(formData, id);
  });
}

const userStatus = document.getElementById('user-status');
if (userStatus) {
  const toggleLabel = input => {
    const label = input.nextElementSibling; // Znajdź element label
    if (input.checked) {
      label.textContent = 'aktywny';
    } else {
      label.textContent = 'nieaktywny';
    }
  };
  const activeInputs = document.querySelector('.active-user');
  const inactiveInputs = document.querySelector('.inactive-user');

  if(activeInputs){
    activeInputs.addEventListener('change', function() {
      toggleLabel(this);
      const id = this.value; // Pobierz wartość 'value' z inputa
      updateUserStatus(id); // Wywołaj funkcję updateStatus z przekazanym ID
    });
  }

  // Dodaj obsługę zdarzenia dla wszystkich inputów 'inactive-training'

if(inactiveInputs){
  inactiveInputs.addEventListener('change', function() {
    toggleLabel(this);
    const id = this.value; // Pobierz wartość 'value' z inputa
    updateUserStatus(id); // Wywołaj funkcję updateStatus z przekazanym ID
  });
}
}
