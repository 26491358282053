/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createTraining = async (data) => {
  try {
    const res = await axios.post(`/api/v1/trainings`,
      data
    );
    if (res.data.status === 'success') {
      showAlert('success', 'Poprawnie dodano trening(i)');
      window.setTimeout(() => {
        location.reload(true);
      }, 2500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 2500);
  }
};

export const addUserToTraining = async (id, data) => {
  try {
      
    const res = await axios.patch(`/api/v1/trainings/addUser/${id}`, {data});
    if (res.status === 200) {
      showAlert('success', 'Zostałes zapisany na trening');
      window.setTimeout(() => {
        location.reload(true);
      }, 2000);
    }
  }catch(err){
    showAlert('danger', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 2000);
    
  }
}

  export const updateTraining = async (data,id) => {
    try {
      const res = await axios.patch(`/api/v1/trainings/${id}`,
       data
      );
      if (res.data.status === 'success') {
        showAlert('success', 'Poprawnie zaktualizowano trening');
        
      }
    } catch (err) {
      showAlert('danger', err.response.data.message);
      // window.setTimeout(() => {
      //   location.reload(true);
      // }, 2500);
    }
  };

  export const deleteTraining = async (id) => {
    try {
      
      const res = await axios.delete(`/api/v1/trainings/${id}`);
      if (res.status === 204) {
        showAlert('success', 'Poprawnie usunięto trening');
        window.setTimeout(() => {
          location.assign(`/`);
        }, 1500);
      }
    }catch(err){
      showAlert('danger', err.response.data.message);
      
    }
  }

  

  export const deleteUserFromTraining = async (id, data) => {
    try {
      
      const res = await axios.patch(`/api/v1/trainings/deleteUser/${id}`, {data});
      if (res.status === 204) {
        showAlert('success', 'Zostałeś wypisany z treningu');
        window.setTimeout(() => {
          location.reload(true);
        }, 2000);
      }
    }catch(err){
      showAlert('danger', err.response.data.message);
      
    }
  }

  export const attendaceUpdate = async (data, id) => {
    try {
      const res = await axios.patch(`/api/v1/trainings/attendence/${id}`, data);
      if (res.data.status === 'success') {
        showAlert('success', 'Poprawnie zmodyfikowano obecność');
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  };


