/* eslint-disable */

import axios from 'axios';
import { showAlert } from './alerts';

export const login = async (email, password) => {
  try {
    const res = await axios.post('/api/v1/users/login', { email, password },{
      headers: {
        'Content-Type': 'application/json'
      }});
      if (res.data.status === 'success') {
        showAlert('success', 'Zalogowano');
      
        // Pobieramy aktualny adres URL
        const currentUrl = new URL(window.location.href);
      
        // Sprawdzamy, czy URL zawiera "login" i "?"
        if (currentUrl.pathname.includes('login') && currentUrl.search.includes('?')) {
          // Zamieniamy "login/?" na "training-confirmation/"
          const redirectUrl = currentUrl.toString().replace(/\/?login\/?./, '/training-confirmation/');
      
          window.setTimeout(() => {
            location.assign(redirectUrl);
          }, 1500);
        } else {
          // Jeżeli nie ma "login" w ścieżce, przekierowujemy na główną stronę
          window.setTimeout(() => {
            location.assign('/');
          }, 1500);
        }
      }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    console.log(err.response.data)
    showAlert('danger', err.response.data.message);
  }
};


export const logout = async () => {
  try {
    const res = await axios.get('/api/v1/users/logout');
    if (res.data.status === 'success') location.assign('/');
  } catch (err) {
    showAlert('danger', 'Error logging out! Try again');
  }
};


export const resetPassword = async email => {
  try {
    const res = await axios.post('/api/v1/users/forgotpassword', {
      email
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Na podany email wysłano link do zmiany hasła');
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};


export const changePassword = async (password, passwordConfirm, token) => {
  try {
    const res = await axios.patch(`/api/v1/users/resetpassword/${token}`, {
      password,
      passwordConfirm
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Hasło zostało zmienione');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};


export const signup = async (
  name,
  surName,
  email,
  phoneNumber,
  password,
  passwordConfirm
) => {
  try {
    const res = await axios.post('/api/v1/users/signup', {
      name,
      surName,
      email,
      phoneNumber,
      password,
      passwordConfirm
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Utworzono konto użytkownik');
      window.setTimeout(() => {
        location.assign('/');
      }, 1500);
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};

export const uploadUserPhoto = async (data, id) => {
  try {
    const res = await axios.patch(`/api/v1/users/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }});
    if (res.data.status === 'success') {
      showAlert('success', 'Zaktualizowano dane');
      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    window.setTimeout(() => {
      location.reload(true);
    }, 1500);
  }
};

export const updateUserStatus = async id => {
  try {
    const res = await axios.patch(`/api/v1/users/block-user/${id}`);
    if (res.status === 200) {
      showAlert('success', 'Zmieniono status');
    }
  } catch (err) {
    showAlert('danger', err.response.data.message);
  }
};